<template>
  <el-dialog
    title="新增员工"
    :visible.sync="isShow"
    width="70%"
    :append-to-body='true'
    :destroyOnClose='true'
    :close-on-click-modal='true'>
    <el-button icon='vxe-icon--plus' size='mini' type='primary' @click="addStaff" style="margin-bottom:10px;">新增员工</el-button>
    <vxe-table
      border
      resizable
      show-overflow
      keep-source
      max-height='400px'
      ref="contactPersonForm"
      row-id="templateId"
      size='small'
      :edit-rules="validRules"
      :data="JZForm.targetLists"
      :edit-config="{trigger: 'manul', mode: 'cell'}"
    >
      <template slot="empty">
        <el-empty :image-size="100" description="暂无数据"></el-empty>
      </template>
      <!-- name: 'input',
          attrs: { type: 'text', placeholder: '请输入姓名' }, -->
      <vxe-column
        field="name"
        title="姓名"
        min-width="100"
        align="center"
        show-overflow
        :edit-render="{
        }"
      >
        <template #default="{ row }">
          <el-input v-model.trim="row.name" size='mini' placeholder='请输入姓名' maxlength='30' showWordLimit></el-input>
        </template>
        <template #edit="{ row }">
          <el-input v-model.trim="row.name" size='mini' placeholder='请输入姓名' maxlength='30' showWordLimit></el-input>
        </template>
      </vxe-column>
      <!-- name: 'select',
          props: {  placeholder: '请选择性别' },
          options: [{ value: '', label: '' },{ value: 'M', label: '男' }, { value: 'F', label: '女' }] -->
      <vxe-column
        field="gender"
        title="性别"
        min-width="100"
        align="center"
        :edit-render="{
        }"
      >
        <template #default="{ row }">
          <el-select placeholder="请选择性别" v-model='row.gender'>
            <el-option value="M" label="男">男</el-option>
            <el-option value="F" label="女">女</el-option>
          </el-select>
        </template>
        <template #edit="{ row }">
          <el-select placeholder="请选择性别" v-model='row.gender'>
            <el-option value="M" label="男">男</el-option>
            <el-option value="F" label="女">女</el-option>
          </el-select>
        </template>
      </vxe-column>
      <!--  name: 'input',
          attrs: { type: 'text', placeholder: '请输入手机号' , maxlength: 11, showWordLimit: true}, -->
      <vxe-column
        field="phone"
        title="手机号"
        min-width="150"
        align="center"
        show-overflow
        :edit-render="{
        }"
      >
        <template #default="{ row }">
          <el-input v-model.trim="row.phone" size='mini' placeholder='请输入手机号' maxlength='11' showWordLimit></el-input>
        </template>
        <template #edit="{ row }">
          <el-input v-model.trim="row.phone" size='mini' placeholder='请输入手机号' maxlength='11' showWordLimit></el-input>
        </template>
      </vxe-column>
      <!--  name: 'input',
          attrs: { type: 'text', placeholder: '请输入邮箱' }, -->
      <vxe-column
        field="email"
        title="邮箱"
        min-width="100"
        align="center"
        show-overflow
        :edit-render="{
        }"
      >
        <template #default="{ row }">
         <el-input v-model.trim="row.email" size='mini' placeholder='请输入邮箱' maxlength='100' showWordLimit></el-input>
        </template>
        <template #edit="{ row }">
          <el-input v-model.trim="row.email" size='mini' placeholder='请输入邮箱' maxlength='100' showWordLimit></el-input>
        </template>
      </vxe-column>
      <vxe-column title="操作" width="80" align="center">
        <template #default="{ row, $rowIndex }">
          <i class="vxe-icon--minus table-action" v-if="$rowIndex !== 0" @click="handleDelete(row, $rowIndex)"></i>
        </template>
      </vxe-column>
    </vxe-table>
    <span slot="footer" class="dialog-footer " style="text-align:right;" >
        <el-button @click="isShow=false" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleEnsure" size="mini">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {addSupplierUser} from '@/api/userCenter';
export default {
  name: 'AddStaff',
  methods: {
    addStaff () {
      let info = {
        name: '',
        gender: '',
        phone: '',
        email: '',
        templateId: Date.now(),
      };
      this.JZForm.targetLists.push(info);
    },
    initPage () {
      this.JZForm.targetLists = [];
      this.isShow = true;
    },
    handleEnsure () {
      if (this.judgeStaff()) {
        return;
      }
      addSupplierUser(this.JZForm.targetLists).then(() => {
        this.isShow = false;
        this.$emit('handleSearch');
      });
    },
    judgeStaff () {
      let staffLists = this.JZForm.targetLists;
      for (var i = 0; i < staffLists.length; i++) {
        var staff = staffLists[i];
        if (!staff.name) {
          this.$JZShowAlert('员工姓名不可为空！', 'error');
          return true;
        }
        if (!staff.gender) {
          this.$JZShowAlert('请选择性别！', 'error');
          return true;
        }
        if (!staff.phone) {
          this.$JZShowAlert('手机号不可为空！', 'error');
          return true;
        }
        if (staff.phone && (!/^1\d{10}$/.test(staff.phone))) {
          this.$JZShowAlert('手机号码格式不正确！', 'error');
          return true;
        }
        if (staff.email && (!/^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/.test(staff.email))) {
          this.$JZShowAlert('邮箱码格式不正确！', 'error');
          return true;
        }
      }
      return false;
    },
    handleDelete (info, index) {
      if (info.name) {
        this.$confirmAlert(`您确定删除[${info.name}]员工信息吗？`, '').then(() => {
          this.JZForm.targetLists.splice(index, 1);
        });
      } else {
        this.JZForm.targetLists.splice(index, 1);
      }
    },
  },
  data () {
    const checkEmail = (rule, value, cb) => {
      const regEmail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === '' || regEmail.test(value)) {
        return cb();
      }
      cb(new Error('请输入合法的邮箱'));
    };
    const validateTel = (rule, value, callback) => {
      const regEx = /^1\d{10}$/;
      if (regEx.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号'));
      }
    };
    const validateName = (rule, value, callback) => {
      if (value) {
        callback();
      } else {
        callback(new Error('请输入姓名'));
      }
    };
    return {
      isShow: false,
      currentStaff: {},
      JZForm: {
        targetLists: [
          {
            name: '',
            gender: '',
            phone: '',
            email: '',
            templateId: Date.now(),
          },
        ],
      },
      validRules: {
        name: [
          { required: true, message: '请输入姓名' },
          { validator: validateName, trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validateTel, trigger: 'blur' },
        ],
        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        email: [
          {validator: checkEmail, trigger: 'blur'},
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
