<template>
  <el-dialog
    title="转让管理员"
    :visible.sync="isShow"
    width="70%"
    :append-to-body='true'
    :destroyOnClose='true'
    :close-on-click-modal='true'>
    <TableWithPagination
      :tableData="targetLists"
      max-height="400px"
      :columnList="columnList"
      :pagination.sync="pagination"
      @size-change="getTargetLists"
      @current-change="getTargetLists"
      @sort-change="getTargetLists"
    ></TableWithPagination>
    <span slot="footer" class="dialog-footer " style="text-align:right;" >
        <el-button @click="isShow=false" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleEnsure" size="mini">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
import {getSupplierUserList, transferAdmin} from '@/api/userCenter';
export default {
  name: 'TransferAdministrator',
  mixins: [InfoMixin],
  methods: {
    initPage () {
      this.isShow = true;
      this.targetLists = [];
      this.currentStaff = {};
      this.$nextTick(() =>{
        this.handleSearch();
      });
    },
    handleEnsure () {
      if (this.currentStaff.userId) {
        transferAdmin({toUserId: this.currentStaff.userId }).then(()=>{
          this.isShow = false;
          this.$emit('handleSearch');
        });
      }
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = {
        pageFlag: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        deleteFlag: 0,
        identity: 'NORMAL',
      };
      currentParams.orderBy = [{
        orderByProperty: 'createTime',
        orderByType: 'DESC',
      }];
      getSupplierUserList(currentParams).then(res=>{
        this.targetLists = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
  },
  data () {
    return {
      isShow: false,
      currentStaff: {},
      columnList: [
        {
          tooltip: true,
          label: '选择员工',
          prop: 'userId',
          fixed: 'left',
          minWidth: '120px',
          render: (h, {row}) => {
            let actions = [];
            actions.push(h('el-radio', {
              props: {
                value: this.currentStaff.userId,
                label: row.userId,
                disabled: row.identity === 'ADMIN',
              },
              on: {
                change: () => {
                  this.currentStaff = row;
                },
              },
            }, ''));
            return h('span', actions);
          },
        },
        {
          tooltip: true,
          label: '员工姓名',
          prop: 'name',
          minWidth: '120px',
        },
        {
          tooltip: true,
          label: '员工账号',
          prop: 'phone',
          minWidth: '120px',
        },
        {
          tooltip: true,
          label: '企业身份',
          prop: 'identity',
          minWidth: '120px',
          format: (val) => {
            return val === 'ADMIN' ? '管理员' : val === 'NORMAL' ? '普通用户' : '--';
          },
        },
        {
          tooltip: true,
          label: '开通时间',
          prop: 'createTime',
          minWidth: '120px',
          format: this.$options.filters['date-format'],
        }],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
