var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"新增员工","visible":_vm.isShow,"width":"70%","append-to-body":true,"destroyOnClose":true,"close-on-click-modal":true},on:{"update:visible":function($event){_vm.isShow=$event}}},[_c('el-button',{staticStyle:{"margin-bottom":"10px"},attrs:{"icon":"vxe-icon--plus","size":"mini","type":"primary"},on:{"click":_vm.addStaff}},[_vm._v("新增员工")]),_c('vxe-table',{ref:"contactPersonForm",attrs:{"border":"","resizable":"","show-overflow":"","keep-source":"","max-height":"400px","row-id":"templateId","size":"small","edit-rules":_vm.validRules,"data":_vm.JZForm.targetLists,"edit-config":{trigger: 'manul', mode: 'cell'}}},[_c('template',{slot:"empty"},[_c('el-empty',{attrs:{"image-size":100,"description":"暂无数据"}})],1),_c('vxe-column',{attrs:{"field":"name","title":"姓名","min-width":"100","align":"center","show-overflow":"","edit-render":{
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"请输入姓名","maxlength":"30","showWordLimit":""},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"row.name"}})]}},{key:"edit",fn:function(ref){
      var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"请输入姓名","maxlength":"30","showWordLimit":""},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"row.name"}})]}}])}),_c('vxe-column',{attrs:{"field":"gender","title":"性别","min-width":"100","align":"center","edit-render":{
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"请选择性别"},model:{value:(row.gender),callback:function ($$v) {_vm.$set(row, "gender", $$v)},expression:"row.gender"}},[_c('el-option',{attrs:{"value":"M","label":"男"}},[_vm._v("男")]),_c('el-option',{attrs:{"value":"F","label":"女"}},[_vm._v("女")])],1)]}},{key:"edit",fn:function(ref){
      var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"请选择性别"},model:{value:(row.gender),callback:function ($$v) {_vm.$set(row, "gender", $$v)},expression:"row.gender"}},[_c('el-option',{attrs:{"value":"M","label":"男"}},[_vm._v("男")]),_c('el-option',{attrs:{"value":"F","label":"女"}},[_vm._v("女")])],1)]}}])}),_c('vxe-column',{attrs:{"field":"phone","title":"手机号","min-width":"150","align":"center","show-overflow":"","edit-render":{
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"请输入手机号","maxlength":"11","showWordLimit":""},model:{value:(row.phone),callback:function ($$v) {_vm.$set(row, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"row.phone"}})]}},{key:"edit",fn:function(ref){
      var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"请输入手机号","maxlength":"11","showWordLimit":""},model:{value:(row.phone),callback:function ($$v) {_vm.$set(row, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"row.phone"}})]}}])}),_c('vxe-column',{attrs:{"field":"email","title":"邮箱","min-width":"100","align":"center","show-overflow":"","edit-render":{
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"请输入邮箱","maxlength":"100","showWordLimit":""},model:{value:(row.email),callback:function ($$v) {_vm.$set(row, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"row.email"}})]}},{key:"edit",fn:function(ref){
      var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","placeholder":"请输入邮箱","maxlength":"100","showWordLimit":""},model:{value:(row.email),callback:function ($$v) {_vm.$set(row, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"row.email"}})]}}])}),_c('vxe-column',{attrs:{"title":"操作","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
      var $rowIndex = ref.$rowIndex;
return [($rowIndex !== 0)?_c('i',{staticClass:"vxe-icon--minus table-action",on:{"click":function($event){return _vm.handleDelete(row, $rowIndex)}}}):_vm._e()]}}])})],2),_c('span',{staticClass:"dialog-footer ",staticStyle:{"text-align":"right"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){_vm.isShow=false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.handleEnsure}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }